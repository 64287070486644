<template>
	<div>
		<h2>{{ $t('horse.production') }}</h2>
		<div class="box">
			<CustomTable
				ref="table"
				id_table="horse_product_ca"
				primaryKey="horse_id"
				:items="families_formatted"
				:busy.sync="table_busy"
				:hideOperationActions="true"
				:hasExportOption="false"
				:showPagination="false"
			/>
		</div>
	</div>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"

	export default {
		name: "HorseProduct",
		mixins: [Navigation],
		props: ['horse_id'],
		data () {
			return {
				families_formatted: [],
				families: [],
				table_busy: true
			}
        },
        created() {
            this.init_component()
		},
		methods: {
            async init_component() {
				this.families = await this.getHorseChildren(this.horse_id)
				this.families_formatted = this.families.filter(f => !!f.enfant)
				this.table_busy = false
            }
		},
		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
		}
	}
</script>
